"use strict";
(() => {
    class HCPConnect {
        constructor() {
            this.#init();
        }
    
        get isMegaMenu() {
            return document.querySelector('.mega-menu-navigation');
        }
    
        get isHCPConfigurator() {
            return document.querySelector('.configurator-revamp');
        }
    
        set addHCPClass(cls) {
            document.body.classList.add(cls);
        }
    
        #checkHCPConnectTemplates() {
            if(this.isMegaMenu) {
                this.addHCPClass = 'hcp_mega-menu';
            }
            if(this.isHCPConfigurator) {
                this.addHCPClass = 'hcp_configurator';
            }
        }
    
        #init() {
            this.#checkHCPConnectTemplates();
        }
    }

    new HCPConnect();
})();